import { forwardRef, HTMLAttributes, ReactElement, useCallback, useMemo } from "react";

import classnames from "classnames";

import { useCurrentInputVibe } from "../../../hooks/useCurrentInputVibe";
import { useCustomError } from "../../../hooks/useCustomError";
import { useCustomInputProps } from "../../../hooks/useCustomInputProps";
import { BaseInput } from "../BaseInput";
import { IconButton } from "../IconButton/IconButton";
import { InputLabel } from "../InputLabel/InputLabel";
import { useMergeClasses } from "../useMergeClasses";

import { zeffySelectClasses, StyledSelect } from "./styles";
import { BaseSelectProps } from "./types";

import { ChevronDown } from "@/icons/outlined";

export const disabledAutoFocusMenuProps = {
  disableAutoFocus: true,
  autoFocus: false,
};

export const BaseSelect = forwardRef<HTMLSelectElement, BaseSelectProps>(
  (
    {
      children,
      className,
      classes: externalClasses,
      vibe,
      "data-test": dataTest,
      disableAutoFocus,
      disabled,
      disableAutocomplete,
      error,
      errorMessage,
      headerRight,
      helperText,
      inputProps,
      inputLabelClasses,
      label,
      MenuProps,
      size,
      tooltipText,
      name,
      placeholder,
      ...rest
    },
    ref
  ): ReactElement<BaseSelectProps> => {
    const currenVibe = useCurrentInputVibe(vibe);
    const classes = useMergeClasses(zeffySelectClasses, externalClasses);

    const currentError = useCustomError(error, errorMessage);
    const customInputProps = useCustomInputProps({ inputProps, disableAutocomplete });

    const mergedMenuProps = useMemo(
      () => ({
        ...MenuProps,
        ...(disableAutoFocus ? disabledAutoFocusMenuProps : undefined),
      }),
      [MenuProps, disableAutoFocus]
    );

    const renderBaseSelectIcon = useCallback(
      (props: { className?: string; open?: boolean }) => {
        return (
          <IconButton
            inline
            size={size === "small" ? "medium" : "large"}
            vibe={currenVibe === "form" || disabled ? "inherit" : "neutral"}
            variant="text"
            className={classnames(
              props.className,
              classes.icon,
              { [classes.iconForm]: currenVibe === "form" },
              { [classes.iconOpen]: props.open }
            )}
            data-test={`${dataTest}-button`}
          >
            <ChevronDown />
          </IconButton>
        );
      },
      [classes.icon, classes.iconForm, classes.iconOpen, currenVibe, dataTest, disabled, size]
    );

    const SelectDisplayProps = useMemo(
      () =>
        ({
          ["data-test"]: dataTest,
        }) as HTMLAttributes<HTMLDivElement>,
      [dataTest]
    );

    return (
      <InputLabel
        className={className}
        classes={inputLabelClasses}
        vibe={currenVibe}
        errorMessage={errorMessage}
        headerRight={headerRight}
        helperText={helperText}
        label={label}
        tooltipText={tooltipText}
        data-test={`${dataTest}-label`}
      >
        <StyledSelect
          variant="standard"
          {...rest}
          MenuProps={mergedMenuProps}
          ref={ref}
          classes={{
            root: classes.root,
            select: classnames(classes.select, {
              [classes.medium]: size !== "small",
              [classes.small]: size === "small",
            }),
          }}
          placeholder={placeholder}
          disabled={disabled}
          IconComponent={renderBaseSelectIcon}
          inputProps={customInputProps}
          SelectDisplayProps={SelectDisplayProps}
          input={<BaseInput vibe={currenVibe} disabled={disabled} error={currentError} size={size} noPadding />}
          name={name}
        >
          {children}
        </StyledSelect>
      </InputLabel>
    );
  }
);

BaseSelect.displayName = "Select";

import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
export type DiscountFragmentFragment = { __typename?: 'DiscountObject', id: string, body: string, amount: number, limit?: number | null, type: Types.DiscountType, remainingUses?: number | null, sortIndex: number, ticketingId: string };

export type DonationFormAmountFragmentFragment = { __typename?: 'DonationFormAmountObject', id: string, amount: number, isRecurrent: boolean, message?: string | null, sortIndex: number, recurrenceInterval: Types.ProductDonationRecurrenceInterval };

export type DonationFormFragmentFragment = { __typename?: 'DonationFormObject', allowCheque: boolean, allowInHonour: boolean, bannerUrl?: string | null, bannerVideoUrl?: string | null, campaignId?: string | null, category: Types.DonationFormCategory, color: string, encourageMonthlyDonations: boolean, hasReceipt: boolean, hideFundraiseButton: boolean, formVolume?: number | null, id: string, path: string, isArchived: boolean, isPrimaryCampaign: boolean, isPrimaryTeam: boolean, locale: Types.Locales, logoUrl?: string | null, notificationEmails?: GraphQL_NotificationEmailsScalar | null, redirectUrl?: string | null, stripeProductId?: string | null, target?: number | null, teamId?: string | null, askForAddress: boolean, allowTeamCreation?: boolean | null, allowFundraiserCreation?: boolean | null, ticketingId?: string | null, organizationId?: string | null };

export type FormFragmentFragment = { __typename?: 'FrontendFormObject', id: string, path: string, locale: Types.Locales, formCategory: Types.FormCategory, campaignId?: string | null, generateQrCode?: boolean | null, seats?: number | null, fields: Array<{ __typename?: 'FormField', id: string, locale: Types.Locales, title: string }>, rates?: Array<{ __typename?: 'TicketingDetailsRate', id: string, seats?: number | null, fields: Array<{ __typename?: 'RateFieldObject', id: string, title: string, locale: Types.Locales }> }> | null };

export type EmailTemplateFragmentFragment = { __typename?: 'EmailTemplateObject', id: string, title: string, subject: string, body: string };

export type QuestionFragmentFragment = { __typename?: 'QuestionObject', id: string, required: boolean, type: Types.QuestionType, sortIndex: number, hasRate: boolean, countAlreadyAnswered: number, choices: Array<{ __typename?: 'ChoiceObject', id: string, sortIndex: number, maximum?: number | null, countAlreadyAnswered?: number | null, choiceFields: Array<{ __typename?: 'ChoiceFieldObject', id: string, locale: Types.Locales, body: string }> }>, questionFields: Array<{ __typename?: 'QuestionFieldObject', id: string, body: string, locale: Types.Locales }>, rateQuestions?: Array<{ __typename?: 'RateQuestionObject', id: string, rateId: string }> | null };

export type AnswerFragmentFragment = { __typename?: 'AnswerObject', id: string, questionId: string, answer?: string | null, ticketId?: string | null, productTicketId?: string | null, choices?: Array<{ __typename?: 'ChoiceObject', id: string, sortIndex: number, choiceFields: Array<{ __typename?: 'ChoiceFieldObject', id: string, locale: Types.Locales, body: string }> }> | null, question?: { __typename?: 'QuestionObject', id: string, sortIndex: number, type: Types.QuestionType, rateQuestions?: Array<{ __typename?: 'RateQuestionObject', id: string, rateId: string }> | null, questionFields: Array<{ __typename?: 'QuestionFieldObject', id: string, locale: Types.Locales, body: string }> } | null };

export const DiscountFragmentFragmentDoc = gql`
    fragment DiscountFragment on DiscountObject {
  id
  body
  amount
  limit
  type
  remainingUses
  sortIndex
  ticketingId
}
    `;
export const DonationFormAmountFragmentFragmentDoc = gql`
    fragment DonationFormAmountFragment on DonationFormAmountObject {
  id
  amount
  isRecurrent
  message
  amount
  sortIndex
  recurrenceInterval
}
    `;
export const DonationFormFragmentFragmentDoc = gql`
    fragment DonationFormFragment on DonationFormObject {
  allowCheque
  allowInHonour
  bannerUrl
  bannerVideoUrl
  campaignId
  category
  color
  encourageMonthlyDonations
  hasReceipt
  hideFundraiseButton
  formVolume
  id
  path
  isArchived
  isPrimaryCampaign
  isPrimaryTeam
  locale
  logoUrl
  notificationEmails
  redirectUrl
  stripeProductId
  target
  teamId
  askForAddress
  allowTeamCreation
  allowFundraiserCreation
  ticketingId
  organizationId
}
    `;
export const FormFragmentFragmentDoc = gql`
    fragment FormFragment on FrontendFormObject {
  id
  path
  locale
  formCategory
  campaignId
  generateQrCode
  seats
  fields {
    id
    locale
    title
  }
  rates {
    id
    seats
    fields {
      id
      title
      locale
    }
  }
}
    `;
export const EmailTemplateFragmentFragmentDoc = gql`
    fragment EmailTemplateFragment on EmailTemplateObject {
  id
  title
  subject
  body
}
    `;
export const QuestionFragmentFragmentDoc = gql`
    fragment QuestionFragment on QuestionObject {
  id
  required
  type
  sortIndex
  hasRate
  countAlreadyAnswered
  choices {
    id
    sortIndex
    maximum
    countAlreadyAnswered
    choiceFields {
      id
      locale
      body
    }
  }
  questionFields {
    id
    body
    locale
  }
  rateQuestions {
    id
    rateId
  }
}
    `;
export const AnswerFragmentFragmentDoc = gql`
    fragment AnswerFragment on AnswerObject {
  id
  questionId
  answer
  ticketId
  productTicketId
  choices {
    id
    sortIndex
    choiceFields {
      id
      locale
      body
    }
  }
  question {
    id
    sortIndex
    type
    rateQuestions {
      id
      rateId
    }
    questionFields {
      id
      locale
      body
    }
  }
}
    `;
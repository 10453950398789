import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { CSSProperties, Styles } from "@mui/styles/withStyles";

import { MenuItemBaseClasses } from "./types";

type MenuItemBaseStyles = Styles<Theme, Record<MenuItemBaseClasses, CSSProperties>>;

const defaultHover = (theme: Theme): CSSProperties => ({
  background: theme.palette.neutralVariant[99],
  backgroundColor: theme.palette.neutralVariant[99],
});

const primarySecondaryHover = (theme: Theme, color: "primary" | "secondary"): CSSProperties => ({
  background: theme.palette[color].lightest,
});

const primarySecondaryStyles = (theme: Theme, color: "primary" | "secondary"): CSSProperties => ({
  color: theme.palette.neutralVariant[20],
  fill: theme.palette.neutralVariant[20],
  "&$selected": {
    background: theme.palette[color].lighter,
    color: theme.palette.primary.main,
    "&:hover": primarySecondaryHover(theme, color),
  },
  "&:hover": primarySecondaryHover(theme, color),
});

const errorHover = (theme: Theme): CSSProperties => ({
  background: theme.palette.error.lightest,
});

export const styles: MenuItemBaseStyles = (theme: Theme) =>
  createStyles<MenuItemBaseClasses, CSSProperties>({
    small: {},
    medium: {},
    selected: {},
    selectedMedium: {},
    selectedSmall: {},
    root: {
      justifyContent: "space-between",
      paddingTop: 8,
      paddingRight: 16,
      paddingBottom: 8,
      paddingLeft: 16,
      "&$medium": theme.typography.body1,
      "&$small": theme.typography.body2,
      "&$selectedMedium": theme.typography.subtitle1,
      "&$selectedSmall": theme.typography.subtitle2,
    },
    default: {
      color: theme.palette.neutralVariant[20],
      fill: theme.palette.neutralVariant[20],
      "&$selected": {
        background: theme.palette.primary.lightest,
        "&:hover": defaultHover(theme),
      },
      "&:hover": defaultHover(theme),
    },
    primary: primarySecondaryStyles(theme, "primary"),
    secondary: primarySecondaryStyles(theme, "secondary"),
    error: {
      color: theme.palette.error.main,
      fill: theme.palette.error.main,
      "&$selected": {
        background: theme.palette.error.lighter,
        "&:hover": errorHover(theme),
      },
      "&:hover": errorHover(theme),
    },
    disabled: {
      opacity: "unset",
      color: theme.palette.neutralVariant[80],
      fill: theme.palette.neutralVariant[80],
      background: "unset",
      backgroundColor: "unset",
      "&:hover": {
        color: theme.palette.neutralVariant[80],
        fill: theme.palette.neutralVariant[80],
        background: "unset",
        backgroundColor: "unset",
      },
    },
    left: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
    },
    startIcon: {
      marginRight: theme.spacing(1),
    },
    endIcon: {
      marginLeft: theme.spacing(1),
    },
  }) as Record<MenuItemBaseClasses, CSSProperties>;

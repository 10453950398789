import { memo } from "react";

import MaterialSelect from "@mui/material/Select";
import { styled, Theme } from "@mui/material/styles";

import { getSizeStyles } from "../BaseInput/styles";
import { InternalClasses } from "../helpers";

export type BaseSelectBaseClasses = "select" | "icon" | "iconForm" | "medium" | "small" | "root" | "iconOpen";

const PREFIX = "BaseSelect";

export const zeffySelectClasses: InternalClasses<typeof PREFIX, BaseSelectBaseClasses> = {
  medium: `${PREFIX}-medium`,
  small: `${PREFIX}-small`,
  select: `${PREFIX}-select`,
  root: `${PREFIX}-root`,
  icon: `${PREFIX}-icon`,
  iconForm: `${PREFIX}-iconForm`,
  iconOpen: `${PREFIX}-iconOpen`,
};

export const StyledSelect = memo(
  styled(MaterialSelect)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffySelectClasses.root}`]: {
      [`& .${zeffySelectClasses.select}`]: {
        borderRadius: theme.radius(1),
        "&:hover, &:focus, &:focus-visible, &:focus-within": {
          backgroundColor: "unset",
        },
        [`&.${zeffySelectClasses.medium}`]: {
          ...getSizeStyles("medium", theme),
          paddingRight: theme.legacySpacing(3) + theme.icon.size.default.small,
        },
        [`&.${zeffySelectClasses.small}`]: {
          ...getSizeStyles("small", theme),
          paddingRight: theme.legacySpacing(3) + theme.icon.size.default.small,
        },
      },
    },

    [`&& .${zeffySelectClasses.icon}`]: {
      color: "unset",
      cursor: "pointer",
      position: "absolute",
      right: theme.spacing(1),
      top: "unset",
      transition: "transform 0.2s",
    },

    [`&& .${zeffySelectClasses.iconOpen}`]: {
      transform: "rotate(180deg)",
    },

    [`&& .${zeffySelectClasses.iconForm}`]: {
      fill: theme.palette.text.form.moderate,
      color: theme.palette.text.form.moderate,
    },
  }))
);

import { useCallback, ChangeEvent, useState, forwardRef } from "react";

import { useCurrentInputVibe } from "../../../hooks/useCurrentInputVibe";
import { Checkbox } from "../Checkbox";
import { OptionGroupLabel } from "../OptionGroupLabel/OptionGroupLabel";

import { BaseCheckboxGroupProps, CheckboxGroupCheckboxProps } from "./types";

export const BaseCheckboxGroup = forwardRef<HTMLInputElement, BaseCheckboxGroupProps>(
  ({
    checkboxes,
    className,
    vibe,
    defaultChecked = {},
    disabled = false,
    error,
    errorMessage,
    helperText,
    label,
    size = "small",
    tooltipText,
    onChange,
    "data-test": dataTest,
    inputRef,
    name,
    values,
  }) => {
    const currentInputVibe = useCurrentInputVibe(vibe);

    const getInitialDefaultChecked = useCallback((): Record<string, boolean> => {
      const checkboxesDefaultChecked = checkboxes.reduce(
        (prev: Record<string, boolean>, { choice, defaultChecked: checkboxDefaultChecked }) => {
          return { ...prev, [choice]: checkboxDefaultChecked ?? false };
        },
        {}
      );
      return { ...checkboxesDefaultChecked, ...defaultChecked };
    }, [checkboxes, defaultChecked]);

    const [internalValues, setInternalValues] = useState<Record<string, boolean>>(getInitialDefaultChecked());

    const currentValues = values ?? internalValues;

    const handleChange = useCallback(
      (checkbox: CheckboxGroupCheckboxProps): NonNullable<CheckboxGroupCheckboxProps["onChange"]> =>
        (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
          const updateValues = { ...currentValues, [checkbox.choice]: checked };
          onChange?.(updateValues);
          checkbox?.onChange?.(event, checked);
          setInternalValues(updateValues);
        },
      [onChange, currentValues]
    );

    return (
      <OptionGroupLabel
        className={className}
        vibe={currentInputVibe}
        data-test={dataTest}
        error={error}
        errorMessage={errorMessage}
        helperText={helperText}
        label={label}
        tooltipText={tooltipText}
      >
        {checkboxes.map((checkbox, index) => {
          const {
            choice,
            className: checkboxClassName,
            vibe: checkboxVibe,
            disabled: checkboxDisabled,
            ...restOfCheckboxProps
          } = checkbox;
          const value = currentValues[choice];
          return (
            <Checkbox
              key={choice}
              data-test="checkbox"
              {...restOfCheckboxProps}
              style={{
                ...(index !== 0 ? { marginTop: "4px" } : {}),
                ...restOfCheckboxProps.style,
              }}
              className={checkboxClassName}
              vibe={checkboxVibe ?? currentInputVibe}
              defaultChecked={value}
              value={value}
              checked={Boolean(value)}
              disabled={checkboxDisabled || disabled}
              onChange={handleChange(checkbox)}
              size={size}
              inputRef={inputRef}
              name={name}
            />
          );
        })}
      </OptionGroupLabel>
    );
  }
);

BaseCheckboxGroup.displayName = "CheckboxGroup";

import { FieldError, FieldErrors } from "react-hook-form";

type GetReturnType = Record<string, unknown> | undefined;

export const get = (data: Record<string, unknown>, path: string): GetReturnType => {
  const pathSegments = path.split(".");

  let result: GetReturnType = data;

  pathSegments.forEach((segment: string) => {
    if (result && typeof result === "object" && segment in result) {
      result = result[segment] as GetReturnType;
    } else {
      result = undefined;
    }
  });

  return result;
};

export const getError = (errors: FieldErrors | undefined, path: string): FieldError | undefined => {
  return errors ? (get(errors, path) as FieldError) : undefined;
};

import { createContext, ReactNode } from "react";

import { CurrentRef } from "../../../hooks/useFormInputRefs";
import { TextFieldProps } from "../TextField";

interface DatePickerTextFieldContextProps {
  "data-test": string | undefined;
  disableAutocomplete: boolean | undefined;
  error: boolean | undefined;
  errorMessage: string | ReactNode;
  isRequired: boolean;
  name: string;
  noAsterisk: boolean | undefined;
  onOpen: () => void;
  refCallback: (currentRef: CurrentRef) => void;
  size: TextFieldProps<never>["size"];
  vibe: TextFieldProps<never>["vibe"];
  placeholder: string | undefined;
}

export const DatePickerTextFieldContext = createContext<DatePickerTextFieldContextProps>({
  "data-test": "",
  disableAutocomplete: false,
  error: false,
  errorMessage: "",
  isRequired: false,
  name: "",
  noAsterisk: false,
  onOpen: () => {},
  refCallback: () => {},
  size: "medium",
  vibe: "neutral",
  placeholder: undefined,
});

import { forwardRef } from "react";

import Typography from "@mui/material/Typography";
import classnames from "classnames";

import { useCustomError } from "../../../hooks/useCustomError";
import { Icon } from "../Icon";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyOptionGroupLabelClasses, StyledRoot } from "./styles";
import { OptionGroupLabelProps } from "./types";

import { Tooltip } from "@/design-system/Tooltip/Tooltip";
import { Info } from "@/icons/outlined";

export const OptionGroupLabel = forwardRef<HTMLDivElement, OptionGroupLabelProps>(
  (
    {
      children,
      className,
      classes: externalClasses,
      style,
      vibe,
      disabled,
      error,
      errorMessage,
      helperText,
      label,
      tooltipText,
      "data-test": dataTest,
    },
    ref
  ) => {
    const currentError = useCustomError(error, errorMessage);
    const classes = useMergeClasses(zeffyOptionGroupLabelClasses, externalClasses);
    return (
      <StyledRoot className={classnames(classes.container, className)} style={style} ref={ref} data-test={dataTest}>
        {(label || tooltipText) && (
          <div className={classes.labelSection}>
            {label && (
              <Typography
                variant="caption"
                className={classnames({
                  [classes.labelForm]: vibe !== "neutral",
                  [classes.labelNeutral]: vibe === "neutral",
                  [classes.labelError]: currentError,
                  [classes.disabledLabel]: disabled,
                })}
              >
                {label}
              </Typography>
            )}
            {tooltipText && (
              <Tooltip title={tooltipText} className={classes.tooltip} arrow placement="top">
                <Icon size="extraSmall" pointer>
                  <Info />
                </Icon>
              </Tooltip>
            )}
          </div>
        )}
        {children}
        {errorMessage ? (
          <Typography variant="caption" className={classes.error}>
            {errorMessage}
          </Typography>
        ) : helperText ? (
          <Typography variant="caption" className={classes.helperText}>
            {helperText}
          </Typography>
        ) : (
          <></>
        )}
      </StyledRoot>
    );
  }
);

OptionGroupLabel.displayName = "OptionGroupLabel";

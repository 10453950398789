import { IconButton } from "../IconButton";

import { ChevronLeft, ChevronRight } from "@/icons/outlined";

export const DatePickerRightArrow = () => (
  <IconButton vibe="neutral" size="medium">
    <ChevronRight />
  </IconButton>
);

export const DatePickerLeftArrow = () => (
  <IconButton vibe="neutral" size="medium">
    <ChevronLeft />
  </IconButton>
);

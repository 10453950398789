import { ReactElement, Ref, useCallback, useState, useRef, useMemo, useContext } from "react";

import { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps } from "@mui/material/TextField";
import {
  DatePicker as MaterialDatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers-pro";
import { getDateTime } from "@simplyk/common";
import { DateTime } from "luxon";
import { FieldValues, useController, useWatch } from "react-hook-form";

import { useLocaleContext } from "../../../contexts/LocaleContext";
import { getLabel, isDate, isInputRequired } from "../../../helpers/validators";
import { useTranslate } from "../../../hooks/useTranslate";
import { arrowIconButton, popover } from "../DateTimePicker/styles";
import { IconButton } from "../IconButton";
import { TextField } from "../TextField";

import { DatePickerLeftArrow, DatePickerRightArrow } from "./DatePickerArrows";
import { DatePickerTextFieldContext } from "./DatePickerTextFieldContext";
import { DatePickerProps } from "./types";

import { useCurrentInputVibe } from "@/hooks/useCurrentInputVibe";
import { useFormInputRefs } from "@/hooks/useFormInputRefs";
import { useValidators } from "@/hooks/useValidators";
import { Date as DateIcon } from "@/icons/outlined";

export const datePickerInputDefaultFormat = "yyyy-LL-dd";

export function DatePicker<T extends FieldValues>({
  inputRef,
  vibe,
  control,
  defaultValue,
  disabled,
  inputProps,
  name,
  rules,
  ref,
  size,
  error,
  errorMessage,
  label,
  "data-test": dataTest,
  dateFormatErrorMessage,
  disableAutocomplete,
  maxDate,
  noAsterisk,
  format,
  datePickerViews,
  openTo,
  placeholder,
}: DatePickerProps<T>): ReactElement<DatePickerProps<T>> {
  const { locale } = useLocaleContext();
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const dateFormat = format || datePickerInputDefaultFormat;

  const formDate = useWatch({ control, name }) as Date;
  const formattedFormDate = formDate
    ? DateTime.fromJSDate(new Date(formDate), { zone: "utc" }).setLocale(locale).toFormat(dateFormat)
    : null;
  const formattedDefaultValue = defaultValue
    ? DateTime.fromJSDate(new Date(defaultValue), { zone: "utc" }).setLocale(locale).toFormat(dateFormat)
    : null;
  const validators = useValidators([
    ...(rules || []),
    isDate(dateFormatErrorMessage || t("dashboard", "form.errors.dateFormat")),
  ]);
  const isRequired = isInputRequired(validators);
  const { field } = useController<T>({
    name,
    control,
    defaultValue: (formattedFormDate || formattedDefaultValue || null) as never,
    rules: validators,
  });

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => {
    setOpen(false);
    field.onBlur();
  }, [field]);

  const handleChange = useCallback(
    (newDate: DateTime | null, _context: PickerChangeHandlerContext<DateValidationError>) => {
      field.onChange(newDate?.toFormat("yyyy-MM-dd"));
    },
    [field]
  );

  const arrowProps = useMemo(() => ({ sx: arrowIconButton, disableRipple: true, tabIndex: -1 }), []);
  const popperRef = useRef<HTMLInputElement>(null);
  const refCallback = useFormInputRefs({ inputRefCallBack: field.ref, inputRef, inputRefs: [popperRef] });

  const currentValue = useMemo(() => {
    if (!field.value) {
      return null;
    }
    return getDateTime(field.value, { zone: "utc" });
  }, [field.value]);

  return (
    <DatePickerTextFieldContext.Provider
      value={{
        "data-test": dataTest,
        disableAutocomplete,
        error,
        errorMessage,
        isRequired,
        name,
        noAsterisk,
        onOpen,
        refCallback,
        size,
        vibe,
        placeholder,
      }}
    >
      <MaterialDatePicker
        disabled={disabled}
        format={openTo ? undefined : dateFormat}
        onChange={handleChange}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        ref={ref as Ref<HTMLDivElement>}
        value={currentValue}
        views={datePickerViews}
        slots={{
          rightArrowIcon: DatePickerRightArrow,
          leftArrowIcon: DatePickerLeftArrow,
          textField: DatePickerField,
        }}
        slotProps={{
          popper: {
            sx: popover,
            anchorEl: popperRef.current,
          },
          nextIconButton: arrowProps,
          previousIconButton: arrowProps,
          textField: { label, inputProps },
        }}
        maxDate={maxDate}
        inputRef={refCallback}
        openTo={openTo}
      />
    </DatePickerTextFieldContext.Provider>
  );
}

const DatePickerField = ({
  disabled,
  inputProps,
  label,
  onBlur,
  onChange,
  onFocus,
  value,
  placeholder: placeholderInput,
  onMouseUp,
  onClick,
}: StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps) => {
  const {
    "data-test": dataTest,
    disableAutocomplete,
    error,
    errorMessage,
    isRequired,
    name,
    noAsterisk,
    onOpen,
    refCallback,
    size,
    vibe,
    placeholder: placeholderContext,
  } = useContext(DatePickerTextFieldContext);
  const currentInputVibe = useCurrentInputVibe(vibe);
  return (
    <TextField
      data-test={dataTest}
      errorMessage={errorMessage}
      error={error}
      disableAutocomplete={disableAutocomplete}
      inputRef={refCallback}
      vibe={currentInputVibe}
      disabled={disabled}
      endAdornment={
        <IconButton
          inline
          size={size === "small" ? "medium" : "large"}
          vibe={currentInputVibe === "form" ? "inherit" : "neutral"}
          variant="text"
          disabled={disabled}
          onClick={!disabled ? onOpen : undefined}
        >
          <DateIcon />
        </IconButton>
      }
      inputProps={inputProps}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      size={size}
      value={value}
      label={getLabel(isRequired, label, noAsterisk)}
      placeholder={placeholderContext || placeholderInput}
      onMouseUp={onMouseUp}
      onClick={onClick}
    />
  );
};

import { memo } from "react";

import { styled, Theme } from "@mui/material/styles";

import { InternalClasses } from "../helpers";

export type OptionGroupLabelBaseClasses =
  | "container"
  | "labelSection"
  | "labelForm"
  | "labelNeutral"
  | "labelError"
  | "disabledLabel"
  | "tooltip"
  | "error"
  | "helperText";

const PREFIX = "OptionGroupLabel";

export const zeffyOptionGroupLabelClasses: InternalClasses<typeof PREFIX, OptionGroupLabelBaseClasses> = {
  container: `${PREFIX}-container`,
  labelSection: `${PREFIX}-labelSection`,
  labelForm: `${PREFIX}-labelForm`,
  labelNeutral: `${PREFIX}-labelNeutral`,
  labelError: `${PREFIX}-labelError`,
  tooltip: `${PREFIX}-tooltip`,
  disabledLabel: `${PREFIX}-disabledLabel`,
  error: `${PREFIX}-error`,
  helperText: `${PREFIX}-helperText`,
};

export const StyledRoot = memo(
  styled("div")(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyOptionGroupLabelClasses.container}`]: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },

    [`& .${zeffyOptionGroupLabelClasses.labelSection}`]: {
      alignItems: "center",
      display: "inline-flex",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(1),
    },

    [`& .${zeffyOptionGroupLabelClasses.labelForm}`]: {
      color: theme.palette.text.form.quiet,
    },

    [`& .${zeffyOptionGroupLabelClasses.labelNeutral}`]: {
      color: theme.palette.text.neutral.quiet,
    },

    [`& .${zeffyOptionGroupLabelClasses.labelError}`]: {
      color: theme.palette.text.danger.quiet,
    },

    [`& .${zeffyOptionGroupLabelClasses.tooltip}`]: {
      marginLeft: theme.spacing(0.5),
    },

    [`& .${zeffyOptionGroupLabelClasses.disabledLabel}`]: {
      opacity: 0.4,
    },

    [`& .${zeffyOptionGroupLabelClasses.error}`]: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.danger.quiet,
    },

    [`& .${zeffyOptionGroupLabelClasses.helperText}`]: {
      marginTop: theme.spacing(1),
      color: theme.palette.text.neutral.quiet,
    },
  }))
);

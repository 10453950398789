import { IsoLocale, getShortUtc, isDateSameDay } from "@simplyk/common";

interface DateFormatterRange {
  start?: Date | number | null;
  end?: Date | number | null;
}

export interface DateFormatterProps {
  dates?: DateFormatterRange[];
  isoLocale?: IsoLocale;
  dateFormatOptions?: Intl.DateTimeFormatOptions;
  "data-test"?: string;
  noIcon?: boolean;
}

export const isEventOneDay = (start?: Date | number | null, end?: Date | number | null) =>
  start && end && isDateSameDay(new Date(start), new Date(end));

export const formatDate = (
  start?: Date | number | null,
  isoLocale?: IsoLocale,
  options?: Intl.DateTimeFormatOptions
) =>
  start
    ? new Date(start).toLocaleDateString(
        isoLocale,
        options || {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        }
      )
    : "";

export const formatDateTime = (date?: Date | number | null, isoLocale?: IsoLocale) =>
  date
    ? new Date(date).toLocaleTimeString(isoLocale, {
        hour: "numeric",
        minute: "2-digit",
      })
    : "";

export const formatDateUtc = (date?: Date | number | null, isoLocale?: IsoLocale) =>
  date ? getShortUtc(date, isoLocale) : "";

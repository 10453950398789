import { forwardRef, Ref, RefAttributes, type JSX } from "react";

import { FieldValues } from "react-hook-form";

import { BaseCheckboxGroup } from "./BaseCheckboxGroup";
import { FormCheckboxGroup } from "./FormCheckboxGroup";
import { CheckboxGroupProps } from "./types";

function CheckboxGroupInner<T extends FieldValues>(
  { control, name, ...rest }: CheckboxGroupProps<T>,
  ref: Ref<HTMLInputElement>
) {
  return control && name ? (
    <FormCheckboxGroup control={control} name={name} {...rest} ref={ref} />
  ) : (
    <BaseCheckboxGroup {...rest} name={name} ref={ref} />
  );
}

CheckboxGroupInner.displayName = "CheckboxGroup";

export const CheckboxGroup = forwardRef(CheckboxGroupInner) as <T extends FieldValues>(
  props: CheckboxGroupProps<T> & RefAttributes<HTMLButtonElement>
) => JSX.Element;

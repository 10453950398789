import { forwardRef, Ref, RefAttributes, useCallback, useEffect, useMemo, useRef, type JSX } from "react";

import { FieldValues, Path, PathValue, useController, useWatch } from "react-hook-form";

import { getLabel, isInputRequired } from "../../../helpers/validators";

import { BaseCheckboxGroup } from "./BaseCheckboxGroup";
import { BaseCheckboxGroupProps, FormCheckboxGroupProps } from "./types";

import { useFormInputRefs } from "@/hooks/useFormInputRefs";
import { useValidators } from "@/hooks/useValidators";

function FormCheckboxGroupInner<T extends FieldValues>(
  { control, defaultChecked, name, rules, onChange, noAsterisk, ...rest }: FormCheckboxGroupProps<T>,
  ref: Ref<HTMLInputElement>
) {
  const elementRef = useRef<HTMLElement | null>(null);
  const validators = useValidators(rules);
  const isRequired = isInputRequired(validators);
  const watchedValue = useWatch({ control, name });
  const currentDefaultChecked = watchedValue ?? (defaultChecked as PathValue<T, Path<T>>);

  const { field } = useController<T>({
    name,
    control,
    defaultValue: currentDefaultChecked,
    rules: validators,
  });

  const handleChange = useCallback<NonNullable<BaseCheckboxGroupProps["onChange"]>>(
    (values: Record<string, boolean>) => {
      onChange?.(values);
    },
    [onChange]
  );

  const refCallBack = useFormInputRefs({
    inputRefCallBack: (element: HTMLInputElement | null) => {
      field.ref(element);
      elementRef.current = element;
    },
  });

  // use watchedValue instead of field.value because field.value is staled when using FormCheckboxGroup in the customAnswerOfParticipant
  const values: Record<string, boolean> = useMemo(() => {
    return (watchedValue as string[])?.reduce(
      (prev, current) => {
        return { ...prev, [current]: true };
      },
      {} as Record<string, boolean>
    );
  }, [watchedValue]);

  /* Workaround to scroll checkbox element into view when focused on iOS */
  useEffect(() => {
    const element = elementRef?.current;

    if (!element) {
      return;
    }

    let wasClicked = false;

    const handlePress = () => {
      wasClicked = true;
    };

    const handleFocus = () => {
      if (!wasClicked) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      // Reset the flag after handling focus
      wasClicked = false;
    };

    element.addEventListener("mousedown", handlePress);
    element.addEventListener("touchstart", handlePress);
    element.addEventListener("focus", handleFocus);

    return () => {
      element.removeEventListener("mousedown", handlePress);
      element.removeEventListener("touchstart", handlePress);
      element.removeEventListener("focus", handleFocus);
    };
  }, []);

  // This is an uncontrolled component, and that changing its value in the form directly (using setValue for example), will not change what's rendered in the DOM.
  return (
    <BaseCheckboxGroup
      {...rest}
      ref={ref}
      inputRef={refCallBack}
      onChange={handleChange}
      label={getLabel(isRequired, rest.label, noAsterisk)}
      name={name}
      values={values}
    />
  );
}

FormCheckboxGroupInner.displayName = "FormCheckboxGroup";

export const FormCheckboxGroup = forwardRef(FormCheckboxGroupInner) as unknown as <T extends FieldValues>(
  props: FormCheckboxGroupProps<T> & RefAttributes<HTMLInputElement>
) => JSX.Element;

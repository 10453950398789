import { forwardRef, Ref, RefAttributes, type JSX } from "react";

import { FieldValues } from "react-hook-form";

import { BaseSelect } from "./BaseSelect";
import { FormSelect } from "./FormSelect";
import { SelectProps } from "./types";

function SelectInner<T extends FieldValues>(
  { control, rules, name, ...rest }: SelectProps<T>,
  ref: Ref<HTMLSelectElement>
) {
  return control && name ? (
    <FormSelect {...rest} ref={ref} control={control} name={name} rules={rules} />
  ) : (
    <BaseSelect {...rest} name={name} ref={ref} />
  );
}

SelectInner.displayName = "Select";

export const Select = forwardRef(SelectInner) as <T extends FieldValues>(
  props: SelectProps<T> & RefAttributes<HTMLSelectElement>
) => JSX.Element;

import { forwardRef } from "react";

import MaterialMenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";

import { Icon } from "../Icon";
import { ForwardRefComponentDS, WithStylesProps } from "../types";

import { styles } from "./styles";
import { MenuItemBaseClasses, MenuItemProps } from "./types";

const InternalMenuItem = forwardRef<HTMLLIElement, WithStylesProps<MenuItemProps, MenuItemBaseClasses>>(
  (
    { children, classes, className, color = "default", disabled = false, endIcon, startIcon, size = "small", ...rest },
    ref
  ) => {
    const hasColor = color === "primary" || color === "secondary" || color === "error";
    return (
      <MaterialMenuItem
        {...rest}
        disabled={disabled}
        className={classnames(className, classes.root, {
          [classes.default]: !hasColor,
          [classes[color]]: hasColor,
          [classes.disabled]: disabled,
          [classes.small]: size === "small",
          [classes.medium]: size === "medium",
        })}
        classes={{
          selected: classnames(classes.selected, {
            [classes.selectedSmall]: size === "small",
            [classes.selectedMedium]: size === "medium",
          }),
        }}
        ref={ref}
      >
        <span className={classes.left}>
          {startIcon && (
            <Icon vibe="inherit" size={size} className={classes.startIcon}>
              {startIcon}
            </Icon>
          )}
          {children}
        </span>
        {endIcon && (
          <Icon vibe="inherit" size={size} className={classes.endIcon}>
            {endIcon}
          </Icon>
        )}
      </MaterialMenuItem>
    );
  }
);

InternalMenuItem.displayName = "InternalMenuItem";

// TODO MUI v5 Update withStyles type post JSS to emotion migration
export const MenuItem = withStyles(styles, { name: "MenuItem" })(
  InternalMenuItem as never
) as unknown as ForwardRefComponentDS<MenuItemProps, HTMLLIElement, MenuItemBaseClasses>;

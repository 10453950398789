import { Theme } from "@mui/material/styles";
import { CreateCSSProperties } from "@mui/styles/withStyles";

export const arrowIconButton: CreateCSSProperties = {
  "&:hover, &:focus, &:focus-visible, &:focus-within": {
    backgroundColor: "unset",
    outline: "unset",
  },
};

export const popover = (theme: Theme): CreateCSSProperties => ({
  "& .MuiPaper-root": {
    boxShadow: theme.boxShadow.base.strong,
  },
  "& .MuiToolbar-root, .MuiPaper-root .MuiTabs-root": {
    backgroundColor: theme.palette.tertiary.dark,
  },
  "& .MuiPickersBasePicker-pickerView": {
    "& .MuiPickersDay-daySelected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.tertiary.contrastText,
    },
  },
});

import { forwardRef, Ref, useCallback, RefAttributes, type JSX } from "react";

import { SelectChangeEvent } from "@mui/material/Select";
import { FieldValues, useController } from "react-hook-form";

import { getLabel, isInputRequired } from "../../../helpers/validators";

import { BaseSelect } from "./BaseSelect";
import { BaseSelectProps, FormSelectProps } from "./types";

import { useValidators } from "@/hooks/useValidators";

function FormSelectInner<T extends FieldValues>(
  { control, defaultValue, label, name, onBlur, onChange, rules, noAsterisk, ...rest }: FormSelectProps<T>,
  ref: Ref<HTMLSelectElement>
) {
  const validators = useValidators(rules);
  const isRequired = isInputRequired(validators);

  const { field } = useController<T>({
    name,
    control,
    defaultValue: defaultValue as never,
    rules: validators,
  });

  const handleChange: BaseSelectProps["onChange"] = useCallback(
    (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
      onChange?.(event, child);
      field.onChange(event.target.value);
    },
    [field, onChange]
  );

  const handleBlur = useCallback(() => {
    onBlur?.();
    field.onBlur();
  }, [field, onBlur]);

  return (
    <BaseSelect
      {...rest}
      ref={ref}
      onBlur={handleBlur}
      onChange={handleChange}
      value={field.value}
      label={getLabel(isRequired, label, noAsterisk)}
      name={name}
    />
  );
}

FormSelectInner.displayName = "FormSelect";

export const FormSelect = forwardRef(FormSelectInner) as unknown as <T extends FieldValues>(
  props: FormSelectProps<T> & RefAttributes<HTMLSelectElement>
) => JSX.Element;
